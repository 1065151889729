.clock {
  color: rgba(255, 255, 255, 0.7);
  line-height: 1em;
  font-family: 'Roboto', sans-serif;
  font-size: 2em;
  white-space: nowrap;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0.1em;
  padding: 0.05em 0.2em 0.05em 0.1em;
}

.fixed-width-char {
  display: inline-block;
  width: 0.55em; /* Default width for characters */
  text-align: center;
}

/* Specific style for colon character */
.fixed-width-char.colon {
  width: 0.3em; /* Adjust this value as needed for the colon */
  text-align: left;
  position: relative;
  left: -0.04em;
}
