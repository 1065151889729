.main {
  font-size: 25px;
  visibility: hidden;
}
.main-table {
  width: 100%;
  display: table;
}

.cell {
  border-top: 2px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.05);
  padding-bottom: 0.5em;
  padding-top: 0.3em;
  text-align: center;
}

.main-table > div {
  display: table-row;
}

.main-table > div > div {
  display: table-cell;
}

.table-cell {
  display: table-cell;
}

.main-table > div:last-child > div {
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
}

.hour {
  line-height: 1.5em;
  color: #fff;
}

.icon-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 2.2em;
  height: 2.2em;
}

.wind {
  opacity: 0.7;
  font-size: 0.9em;
  line-height: 0.9em;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wind-icon {
  width: 1em;
  height: 1em;
}

.day-row {
  width: 2em;
  position: relative;
  background-color: #003a5a;
  text-align: center;
  vertical-align: top;
}

.move-left {
  position: relative;
  left: -0.7em;
  bottom: -5.3em;
  transform: rotate(-90deg);
}

.day-text {
  position: absolute;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.2em;
  white-space: nowrap;
  /* font-weight: bold; */
}

.temperature {
  font-size: 1.4em;
  line-height: 1em;
  color: rgba(255, 255, 255, 0.8);
  font-weight: bold;
}

.temperature-value {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0 0.3em;
  border-radius: 0.2em;
  display: inline-block;
  line-height: 1.4em;
}

.current-weather-temperature__value {
  color: rgba(255, 255, 255, 0.8);
  font-size: 2.5em;
  line-height: 0.8em;
  text-align: center;
  margin-top: 0.2em;
}
.current-weather-temperature img {
  width: 1.2em;
  height: 1.2em;
}

.sun-icon {
  width: 1em;
  height: 1em;
  position: relative;
  margin-right: 0.25em;
  display: inline-block;
  top: 0.1em;
}

.moon-icon {
  width: 0.75em;
  height: 0.75em;
  position: relative;
  margin-left: 0.25em;
  margin-right: 0.2em;
  display: inline-block;
  left: -0.1em;
}

.sunrise-sunset {
  margin-top: 0.1em;
  font-size: 1.1em;
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  line-height: 1.1em;
  padding-right: 2.5em;
}

.city {
  line-height: 1.2em;
  color: rgba(255, 255, 255, 0.8);
  text-align: right;
}

.temperature-sunrize-wrapper {
  display: flex;
  flex-direction: row;
}

.bottom {
  display: flex;
  flex-direction: row;
}

.header__today {
  display: flex;
  flex-direction: column;
}

.table {
  display: table;
}

.table-row {
  display: table-row;
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
}

.clock-wrapper {
  padding-top: 0.35em;
  padding-left: 0.3em;
}

.disabled {
  opacity: 0.5;
}

.current-weather-icon-wrapper {
  text-align: center;
  height: 2.9em;
  margin-right: 0.1em;
}

.current-weather-icon {
  width: 2.9em;
  height: 2.9em;
}

.current-temperature-and-icon {
  display: flex;
  flex-direction: row;
}

.temp-blue {
  color: #44eeff;
}

.temp-yellow {
  color: #ffcc77;
}

.bottom__forecast {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0.2em;
  > .day-forecast:last-child {
    /* border-right: 2px solid rgba(255, 255, 255, 0.1); */
  }
}
.day-forecast {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9em;
  width: calc(100% / 6);
  display: flex;
  flex-direction: column;
  padding-right: 0.2em;
  /* border-left: 2px solid rgba(255, 255, 255, 0.1); */
}

.day-forecast__day {
  background-color: #003a5a;
  padding: 0.1em 0.3em;
  text-align: center;
}

.day-forecast__bottom {
  padding: 0.3em 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.day-forecast__bottom__temperatures {
  font-size: 1.4em;
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0.1em 0.2em;
  border-radius: 0.2em;
  text-align: center;
  min-width: 1.5em;
  margin-right: 0.2em;
}

.day-forecast__bottom__weather {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.forecast-day-icon {
  height: 2.1em;
}

.forecast-day-icon .icon {
  position: relative;
  top: -0.3em;
}

.header__right {
  float: right;
  display: flex;
  flex-direction: row;
}

.day-and-date {
  font-size: 1.6em;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 0.35em;
  margin-left: 0.25em;
}

.main__header {
  display: flex;
  flex-direction: row;
}

.current-wind {
  margin-top: 0.2em;
  color: #fff;
  opacity: 0.7;
  display: flex;
}

.current-wind img {
  position: relative;
  top: 0.1em;
  left: 0.1em;
  margin-right: 0.2em;
}

.current-humidity {
  color: #fff;
  opacity: 0.7;
  display: flex;
}

.current-humidity img {
  height: 1.2em;
}

.humidity-icon {
  width: 1.2em;
}

.wind-and-humidity {
  display: flex;
  flex-direction: column;
}

.settings {
  padding-top: 1.1em;
  text-align: center;
  opacity: 0.7;
  padding-left: 1.2em;
  padding-right: 0.5em;
}

.settings img {
  width: 1.2em;
  height: 1.2em;
}
